import { createSlice } from "@reduxjs/toolkit";
import { getProfileData, updateProfile, sendProfileEmailOTP, verifyAddEmailOTP, sendProfilePhoneOTP, verifyAddPhoneOTP, updatePassword, createNewPassword } from "./profileActions";

const initialState = {
   loading: null,
   PhoneOtp: null,
   success: null,
   status: null,
   error: null,
   message: null,
   sessionId: null,
   addCredential: null,
   data: [],
}

const profileSlice = createSlice({
   name: "profile",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      // get profile data
      builder.addCase(getProfileData.pending, (state,) => {
         state.loading = true
      })
      builder.addCase(getProfileData.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.data = payload?.response?.data
         state.status = payload?.response?.status
         state.success = true
         state.message = ""
      })
      builder.addCase(getProfileData.rejected, (state, { payload }) => {
         state.loading = false;
         state.error = true
         state.status = payload.status
         state.message = payload.data.message
      })

      // update profile
      builder.addCase(updateProfile.pending, (state,) => {
         state.loading = true
      })
      builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.data = payload?.response?.data
         state.status = payload?.response?.status
         state.success = true
         state.message = ""
      })
      builder.addCase(updateProfile.rejected, (state, { payload }) => {
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })

      // send profile mail OTP
      builder.addCase(sendProfileEmailOTP.pending, (state) => {
         state.loading = true
      })
      builder.addCase(sendProfileEmailOTP.fulfilled, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false
         state.success = true
         state.status = payload?.response?.status
         state.message = payload?.response?.data?.message
         state.sessionId = payload?.response?.data?.token
         state.addCredential = payload?.data
      })
      builder.addCase(sendProfileEmailOTP.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })

      // verify profile mail OTP
      builder.addCase(verifyAddEmailOTP.pending, (state) => {
         state.loading = true
      })
      builder.addCase(verifyAddEmailOTP.fulfilled, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false
         state.success = true
         state.data = payload?.response?.data
         state.status = payload?.response?.status
         state.message = null
         state.sessionId = null
         state.addCredential = null
      })
      builder.addCase(verifyAddEmailOTP.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })

      // send phone OTP
      builder.addCase(sendProfilePhoneOTP.pending, (state) => {
         state.loading = true
      })
      builder.addCase(sendProfilePhoneOTP.fulfilled, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false
         state.success = true
         state.PhoneOtp = payload?.response?.data?.otp
         state.status = payload?.response?.status
         state.message = payload?.response?.data?.message
         state.sessionId = payload?.response?.data?.token
         state.addCredential = payload?.data
      })
      builder.addCase(sendProfilePhoneOTP.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })

      // verify profile phone OTP
      builder.addCase(verifyAddPhoneOTP.pending, (state) => {
         state.loading = true
      })
      builder.addCase(verifyAddPhoneOTP.fulfilled, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false
         state.success = true
         state.data = payload?.response?.data
         state.status = payload?.response?.status
         state.message = null
         state.sessionId = null
         state.addCredential = null
      })
      builder.addCase(verifyAddPhoneOTP.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })

      // update Profile password
      builder.addCase(updatePassword.pending, (state) => {
         state.loading = true
      })
      builder.addCase(updatePassword.fulfilled, (state, { payload }) => {
         console.log("slice", payload);
         state.loading = false
         state.success = true
         state.status = payload?.response?.status
         state.message = null
         state.sessionId = null
         state.addCredential = null
      })
      builder.addCase(updatePassword.rejected, (state, { payload }) => {
         console.log("slice", payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })

      // Create Profile password
      builder.addCase(createNewPassword.pending, (state) => {
         state.loading = true
      })
      builder.addCase(createNewPassword.fulfilled, (state, { payload }) => {
         console.log("slice", payload);
         state.loading = false
         state.success = true
      })
      builder.addCase(createNewPassword.rejected, (state, { payload }) => {
         console.log("slice", payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })
   }
})

export default profileSlice.reducer