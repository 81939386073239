import React, { useEffect, useState } from "react";
import "./Security.css";
import { Button, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import {
	createNewPassword,
	getProfileData,
	sendProfileEmailOTP,
	sendProfilePhoneOTP,
	updatePassword,
} from "../../../../redux/features/Profile/profileActions";
import { Toaster, toast } from "react-hot-toast";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import { useNavigate } from "react-router-dom";

const Security = () => {
	const navigate = useNavigate();
	const [otp, setOtp] = useState("");
	const [countdown, setCountdown] = useState(4);
	const [resendClicked, setResendClicked] = useState(false);
	const dispatch = useDispatch();
	const [currentPass, setCurrentPass] = useState("");
	const [newPass, setNewPass] = useState("");
	const [confirmNewPass, setConfirmNewPass] = useState("");

	const [errMsg, setErrMsg] = useState("");

	const {
		data,
		data: { password },
		sessionId,
	} = useSelector((state) => state.profile);

	const [createNewPass, setCreateNewPass] = useState();

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			const { email, contact } = user;
			if (email && contact) {
				dispatch(getProfileData({ email }));
			} else if (email) {
				dispatch(getProfileData({ email }));
			} else if (contact) {
				dispatch(getProfileData({ phone: contact.substring(2) }));
			}
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);
		if (countdown === 0) {
			clearInterval(intervalId);
		}
		return () => clearInterval(intervalId);
	}, [resendClicked, countdown]);

	const resendHandler = () => {
		setCountdown(40);
		setResendClicked(true);
		if (data) {
			const { email, contact } = data;
			if (email && contact) {
				dispatch(sendProfileEmailOTP({ email })).then((res) => {
					console.log("resendRes", res);
					if (res.payload.response.status === 200) {
						toast.success(
							`OTP resent to ${data.email && data.email}`
						);
					}
				});
			} else if (email) {
				dispatch(sendProfileEmailOTP({ email })).then((res) => {
					console.log("resendRes", res);
					if (res.payload.response.status === 200) {
						toast.success(
							`OTP resent to ${data.email && data.email}`
						);
					}
				});
			} else if (contact) {
				dispatch(
					sendProfilePhoneOTP({ phone: contact.substring(2) })
				).then((res) => {
					console.log("resendRes", res);
					if (res.payload.response.status === 200) {
						toast.success(
							`OTP resent to ${data.contact && data.contact}`
						);
					}
				});
			}
		}
	};

	const sendOTPhandler = () => {
		setCreateNewPass(true);
		if (data) {
			const { email, contact } = data;
			console.log(email, contact);
			if (email && contact) {
				dispatch(sendProfileEmailOTP({ email })).then((res) => {
					console.log("resendRes", res);
					if (res?.payload?.response?.status === 200) {
						toast.success(
							`OTP sent to ${data.email && data.email}`
						);
					}
				});
			} else if (email) {
				dispatch(sendProfileEmailOTP({ email })).then((res) => {
					console.log("resendRes", res);
					if (res?.payload?.response?.status === 200) {
						toast.success(
							`OTP sent to ${data.email && data.email}`
						);
					}
				});
			} else if (contact) {
				dispatch(
					sendProfilePhoneOTP({ phone: contact.substring(2) })
				).then((res) => {
					console.log("resendRes", res);
					if (res?.payload?.response?.status === 200) {
						toast.success(
							`OTP sent to ${data.contact && data.contact}`
						);
					}
				});
			}
		}
	};

	const passwordRegex =
		/^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,}$/;

	const validatePassword = () => {
		if (!passwordRegex.test(newPass)) {
			setErrMsg(
				"Password must be at least 8 characters long and include at least one uppercase, one lowercase, one special character, and one number"
			);
			return false;
		}
		if (newPass !== confirmNewPass) {
			toast.error("New password and confirm password must match");
			return false;
		}
		if (otp.length !== 6) {
			toast.error("OTP Should be 6 Numbers");
			return false;
		}
		return true;
	};
	const changePassValidator = () => {
		if (!currentPass || !newPass || !confirmNewPass) {
			toast.error("Fields Cannot Be Empty !");
			return false;
		}
		if (!passwordRegex.test(newPass)) {
			setErrMsg(
				"Password must be at least 8 characters long and include at least one uppercase, one lowercase, one special character, and one number"
			);
			return false;
		}
		if (newPass !== confirmNewPass) {
			toast.error("New password and confirm password must match");
			return false;
		}
		return true;
	};

	const changePassHandler = () => {
		const data = { currentPass, newPass };
		if (!changePassValidator()) return;
		console.log("changePassHandler", data);
		dispatch(updatePassword(data)).then((res) => {
			console.log("ChangeRes", res);
			if (res?.payload?.response?.status === 200) {
				toast.success(`Your Password Has been Changed`);
				setTimeout(() => {
					navigate("/profile/editprofile");
				}, 100);
			}
		});
	};

	const newPassHandler = () => {
		const nData = { sessionId, otp, newPass };
		if (!validatePassword()) return;
		console.log(nData);
		dispatch(createNewPassword(nData)).then((res) => {
			console.log("res", res);
			if (res?.payload?.response?.status === 200) {
				toast.success(`Password Has been Created`);
				setTimeout(() => {
					navigate("/profile/editprofile");
				}, 50);
			}
		});
	};

	return (
		<div className='Security mt-2'>
			<Toaster />
			<div className='flex items-center justify-between mt-2 w-[650px]'>
				<h1 className='text-lg font-semibold  text-[#2B2B2B]'>
					{password ? "Change Password" : "Create Password"}
				</h1>
				<div>
					<svg
						width='67'
						height='70'
						viewBox='0 0 79 81'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
					>
						<path
							d='M45.4696 10.5724V11.4114L46.2958 11.5572C47.3429 11.742 48.0277 12.4949 48.0277 13.4713V20.9746C48.0277 22.0425 47.111 22.9592 46.0431 22.9592H32.4006C31.2478 22.9592 30.4166 22.1283 30.416 20.9753C30.416 20.9751 30.416 20.975 30.416 20.9749L30.416 13.4719C30.416 12.4955 31.1008 11.7426 32.1479 11.5578L32.9741 11.412V10.573V7.24775C32.9741 3.79247 35.7666 1 39.2218 1C42.6771 1 45.4696 3.79261 45.4696 7.24775V10.5724ZM41.4707 19.3687L41.4682 19.3586L41.4654 19.3484L41.1311 18.1193C41.6488 17.6184 42.0133 16.9265 42.0133 16.1149C42.0133 14.6557 40.8282 13.3241 39.2224 13.3241C37.7632 13.3241 36.4316 14.5091 36.4316 16.1149C36.4316 16.997 36.802 17.6574 37.3102 18.1305L36.9783 19.3478L36.9756 19.3577L36.9731 19.3675C36.739 20.3008 37.4228 21.2931 38.4546 21.2931H39.989C41.023 21.2931 41.7028 20.2984 41.4707 19.3687ZM41.7799 11.4879H42.7799V10.4879V7.24775C42.7799 5.2245 41.1167 3.68967 39.2218 3.68967C37.1986 3.68967 35.6638 5.35285 35.6638 7.24775V10.4879V11.4879H36.6638H41.7799Z'
							fill='#DBBDF6'
							stroke='#8A2BE2'
							strokeWidth='2'
						/>
						<path
							d='M2.47235 15.2389H25.5168V18.2693H5.03027H4.03027V19.2693V56.4444V57.4444H5.03027H73.412H74.412V56.4444V19.2693V18.2693H73.412H52.9255V15.2389H75.9699H76.0126C76.805 15.2615 77.4429 15.9134 77.4429 16.7112L77.4423 64.5444C77.4423 65.356 76.7819 66.0167 75.9699 66.0167H2.47235C1.66073 66.0167 1 65.3563 1 64.5444V16.7112C1 15.8996 1.66044 15.2389 2.47235 15.2389ZM36.1747 61.8161C36.1747 63.4769 37.5602 64.8626 39.2211 64.8626C40.882 64.8626 42.2676 63.4769 42.2676 61.8161C42.2676 60.1552 40.882 58.7696 39.2211 58.7696C37.5603 58.7696 36.1747 60.1552 36.1747 61.8161Z'
							fill='#DBBDF6'
							stroke='#8A2BE2'
							strokeWidth='2'
						/>
						<path
							d='M54.3416 78.5899H54.3974C54.4006 78.5899 54.4008 78.59 54.4022 78.5906L54.4027 78.5908C54.4063 78.5925 54.4187 78.5989 54.4364 78.6166C54.4544 78.6346 54.4737 78.6603 54.4899 78.692C54.4961 78.7041 54.5012 78.7159 54.5054 78.7272V79.9766C54.5054 79.9854 54.505 79.9926 54.5044 79.9985C54.4985 79.9991 54.4913 79.9996 54.4825 79.9996H24.1285C24.1197 79.9996 24.1124 79.9991 24.1065 79.9985C24.1059 79.9927 24.1055 79.9854 24.1055 79.9766V78.6123C24.1055 78.6035 24.1059 78.5962 24.1065 78.5903C24.1124 78.5897 24.1196 78.5893 24.1285 78.5893H28.4768C29.3552 78.5893 30.1844 77.9444 30.3848 77.0671L32.213 70.7451L46.3127 70.7457L48.1366 77.0529C48.3444 78.1003 49.2579 78.5899 50.0489 78.5899H54.3414L54.3416 78.5899Z'
							fill='#DBBDF6'
							stroke='#8A2BE2'
							strokeWidth='2'
						/>
						<path
							d='M23.5326 36.5779C23.2768 35.725 22.3389 35.2992 21.4861 35.5549L19.4396 36.2371V34.1055C19.4396 33.2526 18.7574 32.4854 17.8194 32.4854C16.9666 32.4854 16.1993 33.1675 16.1993 34.1055L16.1999 36.2371L14.1534 35.5549C13.3005 35.2992 12.4483 35.7256 12.1069 36.5779C11.8511 37.4308 12.2776 38.283 13.1299 38.6244L15.1764 39.3066L13.8977 41.0974C13.3862 41.7796 13.5569 42.8026 14.2384 43.3142C14.4942 43.4849 14.8356 43.655 15.1764 43.655C15.6879 43.655 16.1994 43.3992 16.4551 42.9728L17.7338 41.1819L19.0125 42.9728C19.3533 43.3992 19.7798 43.655 20.2913 43.655C20.632 43.655 20.9734 43.5699 21.2292 43.3142C21.9114 42.8027 22.0821 41.7797 21.57 41.0974L20.4631 39.3066L22.5096 38.6244C23.3619 38.2836 23.7883 37.4307 23.5326 36.5779Z'
							fill='#8A2BE2'
						/>
						<path
							d='M27.7951 38.6244L29.8416 39.3066L28.5629 41.0974C28.0514 41.7796 28.2221 42.8026 28.9037 43.3142C29.1594 43.4849 29.5008 43.655 29.8416 43.655C30.3531 43.655 30.8646 43.3992 31.1203 42.9728L32.399 41.1819L33.6778 42.9728C34.0186 43.3992 34.445 43.655 34.9565 43.655C35.2973 43.655 35.6387 43.5699 35.8944 43.3142C36.5766 42.8027 36.7473 41.7797 36.2352 41.0974L34.9565 39.3066L37.003 38.6244C37.8559 38.3687 38.2817 37.4307 38.026 36.5779C37.7702 35.725 36.8323 35.2992 35.9795 35.5549L34.1043 36.2371V34.1055C34.1043 33.2526 33.4221 32.4854 32.4841 32.4854C31.6312 32.4854 30.8639 33.1675 30.8639 34.1055L30.8645 36.2371L28.818 35.5549C27.9652 35.2992 27.1129 35.7256 26.7716 36.5779C26.5158 37.4308 27.0279 38.2837 27.7951 38.6244Z'
							fill='#8A2BE2'
						/>
						<path
							d='M48.6863 34.1055C48.6863 33.2526 48.0041 32.4854 47.0661 32.4854C46.2133 32.4854 45.446 33.1675 45.446 34.1055V36.2371L43.3995 35.5549C42.5466 35.2992 41.6943 35.7256 41.353 36.5779C41.0972 37.4308 41.5237 38.283 42.376 38.6244L44.4225 39.3066L43.1437 41.0974C42.6323 41.7796 42.8029 42.8026 43.4845 43.3142C43.7403 43.4849 44.0817 43.655 44.4225 43.655C44.934 43.655 45.4455 43.3992 45.7012 42.9728L46.9799 41.1819L48.2586 42.9728C48.5994 43.3992 49.0259 43.655 49.5373 43.655C49.8781 43.655 50.2195 43.5699 50.4753 43.3142C51.1575 42.8027 51.3282 41.7797 50.8161 41.0974L49.5374 39.3066L51.5839 38.6244C52.4367 38.3687 52.8626 37.4307 52.6068 36.5779C52.3511 35.725 51.4132 35.2992 50.5603 35.5549L48.6862 36.2371L48.6863 34.1055Z'
							fill='#8A2BE2'
						/>
						<path
							d='M64.7155 44.5079C65.5684 44.5079 66.3357 43.8257 66.3357 42.8877C66.3357 42.0349 65.6535 41.2676 64.7155 41.2676H57.042C56.1892 41.2676 55.4219 41.9498 55.4219 42.8877C55.4219 43.7406 56.1041 44.5079 57.042 44.5079H64.7155Z'
							fill='#8A2BE2'
						/>
					</svg>
				</div>
			</div>
			{password ? (
				<form className='securityform-div flex flex-col gap-6 mt-6'>
					<div className='flex flex-col gap-3'>
						<InputLabel
							htmlFor='currentPass'
							className=' text-base font-medium text-[#2B2B2B]'
						>
							Current Password
						</InputLabel>
						<TextField
							id='currentPass'
							placeholder='Enter Your Current Password'
							variant='outlined'
							className='security-form'
							size='small'
							type='password'
							value={currentPass}
							onChange={(e) => setCurrentPass(e.target.value)}
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
							}}
						/>
					</div>
					<div className='flex flex-col gap-3'>
						<InputLabel
							htmlFor='newPass'
							className=' text-xl font-medium text-[#2B2B2B]'
						>
							New Password
						</InputLabel>

						<TextField
							id='newPass'
							placeholder='Think of a strong password'
							variant='outlined'
							className='security-form'
							size='small'
							type='password'
							value={newPass}
							onChange={(e) => {
								setNewPass(e.target.value);
								setErrMsg("");
							}}
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										{passwordRegex.test(newPass) && (
											<CheckCircleIcon
												style={{ color: "#23C552" }}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
						{errMsg && (
							<div className='text-sm w-[695px] -my-2 text-red-600'>
								{errMsg}
							</div>
						)}
					</div>
					<div className='flex flex-col gap-3'>
						<InputLabel
							htmlFor='confirmPass'
							className=' text-xl font-medium text-[#2B2B2B]'
						>
							Confirm Password
						</InputLabel>

						<TextField
							id='confirmPass'
							placeholder='Re - Enter Your Password'
							variant='outlined'
							className='security-form'
							type='password'
							size='small'
							value={confirmNewPass}
							onChange={(e) => setConfirmNewPass(e.target.value)}
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										{newPass === confirmNewPass &&
											confirmNewPass && (
												<CheckCircleIcon
													style={{ color: "#23C552" }}
												/>
											)}
									</InputAdornment>
								),
							}}
						/>
					</div>

					
					<div className='flex gap-4'>
					<Button
						variant='outlined'
						style={{
							borderRadius: "8px",
							padding: "2px 20px",
							color: "#717171",
							borderColor: "#717171",
							textTransform: "Capitalize",
							fontSize: "14px",
							fontWeight: "500",
						}}
						onClick={() => navigate("/dashboard")}
						>
						Cancel
						</Button>
						<Button
							variant='contained'
							style={{
								borderRadius: "8px",
								padding: "2px 20px",
								color: "white",
								backgroundColor: "var(--dark-purple)",
								textTransform: "Capitalize",
								fontSize: "14px",
								fontWeight: "500",
							}}
							onClick={changePassHandler}
						>
						Save
					</Button>
					</div>
					
				</form>
			) : createNewPass ? (
				<form className='securityform-div flex flex-col gap-8 mt-10'>
					<div className='flex flex-col gap-3'>
						<InputLabel
							htmlFor='newPass'
							className=' text-xl font-medium text-[#2B2B2B]'
						>
							Create Password
						</InputLabel>
						<TextField
							id='newPass'
							placeholder='Think of a strong password'
							variant='outlined'
							className='security-form'
							type='password'
							error={errMsg}
							value={newPass}
							onChange={(e) => {
								setNewPass(e.target.value);
								setErrMsg("");
							}}
							size='small'
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										{passwordRegex.test(newPass) && (
											<CheckCircleIcon
												style={{ color: "#23C552" }}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
						{errMsg && (
							<div className='text-sm w-[695px] -my-2 text-red-600'>
								{errMsg}
							</div>
						)}
					</div>

					<div className='flex flex-col gap-3'>
						<InputLabel
							htmlFor='confirmPass'
							className=' text-xl font-medium text-[#2B2B2B]'
						>
							Confirm Password
						</InputLabel>
						<TextField
							id='confirmPass'
							placeholder='Re - Enter Your Password'
							variant='outlined'
							type='password'
							value={confirmNewPass}
							onChange={(e) => setConfirmNewPass(e.target.value)}
							className='security-form'
							size='small'
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
							}}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										{newPass === confirmNewPass &&
											confirmNewPass && (
												<CheckCircleIcon
													style={{ color: "#23C552" }}
												/>
											)}
									</InputAdornment>
								),
							}}
						/>
					</div>

					<div className='flex flex-col gap-3'>
						<InputLabel
							htmlFor='passotp'
							className=' text-xl font-medium text-[#2B2B2B]'
						>
							Enter OTP
						</InputLabel>

						<TextField
							id='passotp'
							placeholder='Enter Your OTP'
							variant='outlined'
							className='security-form'
							type='number'
							value={otp}
							onChange={(e) => setOtp(e.target.value)}
							size='small'
							sx={{
								border: "none",
								"& fieldset": { border: "none" },
							}}
							inputProps={{ maxLength: 6 }}
							InputProps={{
								endAdornment: (
									<InputAdornment position='start'>
										{otp.length === 6 && (
											<CheckCircleIcon
												style={{ color: "#23C552" }}
											/>
										)}
									</InputAdornment>
								),
							}}
						/>
					</div>

					<p className='text-[#888888] text-sm -mb-5'>
						Enter the 6-digit OTP received on Registered{" "}
						{data?.email && data?.phone
							? "Email"
							: data?.email
							? "Email"
							: "Phone"}
					</p>
					<p className=' text-sm text-[#888888]'>
						Didn't Receive the OTP ?
						{countdown > 0 ? (
							<span className='text-sm ml-2'>
								Resend in{" "}
								<span className='font-semibold'>
									{countdown}
								</span>
							</span>
						) : (
							<span
								className='text-mid-purple font-semibold text-sm ml-2 cursor-pointer underline'
								onClick={resendHandler}
							>
								Resend
							</span>
						)}
					</p>

					<div className='flex  gap-5'>
						<Button
							variant='outlined'
							style={{
								borderRadius: 20,
								padding: "6px 35px",
								color: "#717171",
								borderColor: "#717171",
								textTransform: "Capitalize",
								fontSize: "20px",
								fontWeight: "600",
							}}
							onClick={() => navigate("/dashboard")}
						>
							Cancel
						</Button>
						<Button
							variant='contained'
							style={{
								borderRadius: 20,
								padding: "8px 40px",
								color: "white",
								backgroundColor: "var(--dark-purple)",
								textTransform: "Capitalize",
								fontSize: "20px",
								fontWeight: "600",
							}}
							onClick={newPassHandler}
						>
							Save
						</Button>
					</div>
				</form>
			) : (
				<div>
					<Button
						variant='outlined'
						style={{
							borderRadius: 20,
							padding: "6px 35px",
							color: "#F5F5F5",
							fontSize: "18px",
							fontWeight: "600",
							backgroundColor: "#FC9D33",
							textTransform: "Capitalize",
							border: "none",
							marginTop: "3rem",
						}}
						onClick={sendOTPhandler}
					>
						Create Password
					</Button>
				</div>
			)}
		</div>
	);
};

export default Security;
