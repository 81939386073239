import { createSlice } from "@reduxjs/toolkit";
import { signUpUserWithEmail, signUpUserWithPhone, verifySignUpUserWithEmail, verifySignUpUserWithPhone } from './signUpActions'


const initialState = {
   loading: false,
   userInfo: {},
   token: null,
   error: null,
   success: false,
   message: null,
   status: null
}

const signupSlice = createSlice({
   name: "signup",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      //signup with email
      builder.addCase(signUpUserWithEmail.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(signUpUserWithEmail.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.userInfo = payload.data
         state.token = payload.response.data.token
      })
      builder.addCase(signUpUserWithEmail.rejected, (state, { payload }) => {
         console.log(payload);
         state.loading = false;
         state.status = payload.response.status
         state.message = payload.response.data.message || payload.data.message
         state.error = payload.response
      })

      //verify emailOTP
      builder.addCase(verifySignUpUserWithEmail.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(verifySignUpUserWithEmail.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.success = true
         state.userInfo = payload.data.user
         state.token = payload.data.token
         state.status = payload.status
         state.message = "User Successfully Created"
      })
      builder.addCase(verifySignUpUserWithEmail.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
         state.error = true
      })

      // Signup using phone
      builder.addCase(signUpUserWithPhone.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(signUpUserWithPhone.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.userInfo = payload.data
         state.token = payload.data.token
      })
      builder.addCase(signUpUserWithPhone.rejected, (state, { payload }) => {
         console.log(payload);
         state.loading = false;
         state.error = payload.response
         state.status = payload.response.status
         state.message = payload.response.data.message
      })

      // verify phoneOTP
      builder.addCase(verifySignUpUserWithPhone.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(verifySignUpUserWithPhone.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.success = true
         state.userInfo = payload.data.user
         state.token = payload.data.token
         state.status = payload.status
         state.message = "User Successfully Created"
      })
      builder.addCase(verifySignUpUserWithPhone.rejected, (state, { payload }) => {
         console.log(payload);
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
         state.error = true
      })
   }
});


export default signupSlice.reducer