import React from "react";
import "./Help.css";

const Help = () => {
  return (
    <div className='Security mt-2'>
      <div className='flex items-center justify-between mt-2 w-[650px]'>
        <h1 className='text-lg font-semibold text-[#2B2B2B]'>
          Help
        </h1>
      </div>
      <p style={{
        color: "#2B2B2B",
        fontSize: "16px",
        marginTop: "30px", // Added margin-top to the sentence
      }}>
        For any kind of help, please email us at{" "}
        <a
          href="mailto:help@weareora.in"
          style={{
            color: "#4a1e9e", // Adjust the color as per your preference
            textDecoration: "underline", // Add an underline to the text
            fontWeight: "bold", // Make the text bold
            fontSize: "16px", // Adjust the font size as per your preference
          }}
        >
          help@weareora.in
        </a>
        .
      </p>
    </div>
  );
};

export default Help;
