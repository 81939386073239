import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "../../../utils/AxiosInstance";
import { setUserDataLocalStorage } from "../../../utils/setLocalStorage";

export const signUpUserWithEmail = createAsyncThunk(
   "signUpUserWithEmail",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/emailsignup'
      try {
         let res = await AxiosInstance.post(URL, data)
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log("error:", error.response);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   },
)

export const verifySignUpUserWithEmail = createAsyncThunk(
   "verifySignUpUserWithEmail",
   async (data, { rejectWithValue }) => {
      const { email, otp, token } = data
      const URL = '/auth/emailverify'

      try {
         let res = await AxiosInstance.post(URL, { email, otp }, {
            headers: {
               token: token
            }
         })
         console.log("verify : ", res);
         localStorage.clear()
         setUserDataLocalStorage(res.data.user, res)
         return JSON.parse(JSON.stringify(res));
      } catch (error) {
         console.log("error:", error);
         return rejectWithValue({
            status: error.response.status,
            code: error.response.status,
            data: error.response.data
         })
      }
   }
)

export const signUpUserWithPhone = createAsyncThunk(
   "signUpUserWithPhone",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/getotp'
      try {
         let res = await AxiosInstance.post(URL, data)
         // console.log(res);
         return JSON.parse(JSON.stringify(res))
      } catch (error) {
         console.log("error:", error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)

export const verifySignUpUserWithPhone = createAsyncThunk(
   "verifySignUpUserWithPhone",
   async (data, { rejectWithValue }) => {
      const { phone, otp, token } = data
      const URL = '/auth/verifyotp'
      console.log("check", data);
      try {
         let res = await AxiosInstance.post(URL, { phone, otp }, {
            headers: {
               token: token
            }
         })
         console.log("verify : ", res);
         // localStorage.clear()
         // setUserDataLocalStorage(res.data.user, res)
         return JSON.parse(JSON.stringify(res));
      } catch (error) {
         console.log("error:", error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)