import React, { useEffect, useState } from "react";
import "./EventManagement.css";
import Navbar2 from "../../Components/Navbar2/Navbar2";
import LOGO from "../../Assets/oralogopurple.svg";
import { Tooltip } from "@mui/material";
import Event from "./components/Event/Event";
import { useNavigate } from "react-router-dom";

const EventManagement = () => {
	const navigate = useNavigate();
	const [eventLinks, setEventLinks] = useState([
		{ name: "Event", status: true },
		{ name: "Ticket", status: false },
		{ name: "Hosts", status: false },
		{ name: "Invitations", status: false },
		{ name: "Notifications", status: false },
		{ name: "Analytics", status: false },
		{ name: "Attendees", status: false },
		{ name: "Coupon Code", status: false },
	]);
	const [selected, setSelected] = useState(0);

	useEffect(() => {
		if (!localStorage.getItem("token") && !localStorage.getItem("user")) {
			navigate("/login", { replace: true });
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className='bg-[#f4f4f4]'>
			<Navbar2 />
			<div className='event-management flex'>
				<div className='event-sidebar border bg-white'>
					<div className='event-logo'>
						<img src={LOGO} alt='Logo' style={{ height: "40px" }} />
					</div>
					<div className='event-items'>
						{eventLinks.map((item, idx) => {
							if (item?.status) {
								return (
									<div
										key={idx}
										onClick={() => setSelected(idx)}
										className={`${
											selected === idx
												? "event-selected svgs"
												: ""
										} text-sm font-semibold flex gap-5 cursor-pointer`}
									>
										<svg
											width='20px'
											height='20px'
											viewBox='0 0 20 20'
											fill='none'
											xmlns='http://www.w3.org/2000/svg'
											className={`${
												selected === idx
													? "white-svg"
													: ""
											}`}
										>
											<path
												d='M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z'
												fill='#717171'
											/>
										</svg>

										{item?.name}
									</div>
								);
							} else {
								return (
									<Tooltip
										title='Plese Add an Event'
										placement='bottom-end'
									>
										<div
											key={idx}
											className={`text-sm text-gray-100 font-semibold flex gap-5 cursor-not-allowed`}
										>
											<svg
												width='20'
												height='20'
												viewBox='0 0 20 20'
												fill='none'
												xmlns='http://www.w3.org/2000/svg'
												className={`${
													selected === idx
														? "white-svg"
														: ""
												}`}
											>
												<path
													d='M10 15.77L16.18 19.5L14.54 12.47L20 7.74L12.81 7.13L10 0.5L7.19 7.13L0 7.74L5.46 12.47L3.82 19.5L10 15.77Z'
													fill='#717171'
												/>
											</svg>

											{item?.name}
										</div>
									</Tooltip>
								);
							}
						})}
					</div>
				</div>
				<div className='event-content'>
					<Event />
				</div>
			</div>
		</div>
	);
};

export default EventManagement;
