import { createSlice } from "@reduxjs/toolkit";
import { loginAuthStatus, loginWithEmailPassword, loginWithMobilePassword, loginWithEmailOTP, verifyWithEmailOTP, loginWithPhoneOTP, verifyWithPhoneOTP } from "./loginActions";

const initialState = {
   loading: false,
   PhoneOTP: null,
   userInfo: {},
   loggedBy: null,
   token: null,
   error: null,
   success: false,
   message: null,
   status: null
}

const loginSlice = createSlice({
   name: "login",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      // login Auth Status
      builder.addCase(loginAuthStatus.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(loginAuthStatus.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false;
         state.userInfo = payload?.response?.data;
         state.loggedBy = payload?.data
      })
      builder.addCase(loginAuthStatus.rejected, (state, { payload }) => {
         console.log(payload);
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
      })

      // login with email password
      builder.addCase(loginWithEmailPassword.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(loginWithEmailPassword.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userInfo = payload.response.data.user
         state.status = payload.response.status
         state.token = payload.response.data.token
         state.success = true
      })
      builder.addCase(loginWithEmailPassword.rejected, (state, { payload }) => {
         state.error = true
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
      })

      // login with mobile password
      builder.addCase(loginWithMobilePassword.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(loginWithMobilePassword.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userInfo = payload.response.data.user
         state.status = payload.response.status
         state.token = payload.response.data.token
         state.success = true
      })
      builder.addCase(loginWithMobilePassword.rejected, (state, { payload }) => {
         state.error = true
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
      })

      // login with emailOTP
      builder.addCase(loginWithEmailOTP.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(loginWithEmailOTP.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.status = payload.response.status
         state.token = payload.response.data.token
      })
      builder.addCase(loginWithEmailOTP.rejected, (state, { payload }) => {
         state.error = true
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
      })

      // verify with emailOTP
      builder.addCase(verifyWithEmailOTP.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(verifyWithEmailOTP.fulfilled, (state, { payload }) => {
         state.loading = false;
         state.userInfo = payload.response.user
         state.status = payload.response.status
         state.token = payload.response.data.token
         state.success = true
      })
      builder.addCase(verifyWithEmailOTP.rejected, (state, { payload }) => {
         state.error = true
         state.loading = false;
         state.status = payload.status
         state.message = payload.data.message
      })


      // login with phoneOTP
      builder.addCase(loginWithPhoneOTP.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(loginWithPhoneOTP.fulfilled, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.PhoneOTP = payload.response.data.otp
         state.status = payload.response.status
         state.token = payload.response.data.token
      })
      builder.addCase(loginWithPhoneOTP.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.error = payload.response
         state.status = payload.response.status
         state.message = payload.response.data.message
      })

      // verify with PhoneOTP
      builder.addCase(verifyWithPhoneOTP.pending, (state) => {
         state.loading = true;
      })
      builder.addCase(verifyWithPhoneOTP.fulfilled, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false
         state.success = true
         state.userInfo = payload.response.data.user
         state.token = payload.response.data.token
         state.status = payload.response.status
         state.message = "User Login Successfully"
      })
      builder.addCase(verifyWithPhoneOTP.rejected, (state, { payload }) => {
         console.log("payload", payload);
         state.loading = false;
         state.error = true
         state.status = payload.status
         state.message = payload.data.message
      })
   }
})

export default loginSlice.reducer