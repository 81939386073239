/**
 * Predefined libraries
 */
import React, { useEffect } from 'react';

/**
 * Components
 */
import Navbar from '../../Components/Navbar/Navbar';
import Banner from './components/Banner/banner';
import Features from './components/Features/features';
import VideoComponent from './components/Video/video';
import Team from './components/Team/team';
import NewsLetter from './components/Newsletter/newsletter';
import Footer from '../../Components/Footer/Footer';

import PageTitle from '../../Components/PageTitle/pagetitle'


import Aos from "aos";
import "aos/dist/aos.css";
import { useLocation } from 'react-router-dom';

/**
 * Routes
 */

/**
 * Constants
 */
const FILE_NAME = 'LandingPage.js';
const TITLE = 'Ora - Event Hosting Platform'

const LandingPage = () => {
  console.log(`In ${FILE_NAME}`)
  const { pathname } = useLocation();

  useEffect(() => {
    Aos.init({
      duration: 500,
      easing: 'ease-in-out',
    });
    Aos.refresh();
  }, []);

  useEffect(() => {
    document.querySelector('html').style.scrollBehavior = 'auto'
    window.scroll({ top: 0 })
    document.querySelector('html').style.scrollBehavior = ''
  }, [pathname]); // triggered on route change




  return (
    <>
      <PageTitle title={TITLE} />
      <div>
        <Navbar />
        <Banner />
        <Features />
        <VideoComponent />
        {/* <Team /> */}
        <NewsLetter />
        <Footer />
      </div>
    </>
  )
};

export default LandingPage;