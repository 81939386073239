/**
 * 
 * FILE_NAME: App.js
 * Objective: Root File
 * Author: Ankit Dhawan
 * Project: Ora
 * Tech used: react(18.2.0)
 * 
 */
import React from 'react';
import { createBrowserRouter, RouterProvider } from 'react-router-dom';

/**
 * Components
 */
import LandingPage from './Pages/LandingPage/landingpage';
import ContactOra from './Pages/ContactOra/ContactOra';
import Error404 from './Components/404/Error404';
import Login from './Pages/Auth/Login/Login';
import Signup from './Pages/Auth/Signup/Signup';
import Pricing from './Pages/Pricing/Pricing';
import Dashboard from './Pages/Dashboard/Dashboard';
import Profile from './Pages/Profile/Profile';
import EventManagement from './Pages/EventManagement/EventManagement';
import EditProfile from './Pages/Profile/components/EditProfile/EditProfile';
import Security from './Pages/Profile/components/Security/Security';
import Events from './Pages/Profile/components/Events/Events';
import Notifications from './Pages/Profile/components/Notifications/Notifications';
import Help from './Pages/Profile/components/Help/Help';

//Policies
import PrivacyPolicy from './Pages/Policies/PrivacyPolicy/privacypolicy';
import TermsOfUse from './Pages/Policies/TermsofUse/termsofuse';
import ReturnAndRefundPolicy from './Pages/Policies/ReturnAndRefundPolicy/ReturnAndRefundPolicy';
import EULA from './Pages/Policies/EULA/EULA';



// const ProtectedRoute = ({ element, ...rest }) => {
//   const isAuthenticated = useContext(AuthContext); // Replace AuthContext with your actual authentication context

//   if (!isAuthenticated) {
//     // Redirect to login page or show an error message
//     return <Redirect to="/login" />;
//   }

//   return <Route {...rest} element={element} />;
// };


const router = createBrowserRouter([
  {
    path: '/',
    element: <LandingPage />
  },
  {
    path: '/login',
    element: <Login />
  },
  {
    path: '/signup',
    element: <Signup />
  },
  {
    path: '/dashboard',
    element: <Dashboard />
  },
  {
    path: '/contactora',
    element: <ContactOra />
  },
  {
    path: '/eventmanagement',
    element: <EventManagement />
  },
  {
    path: "/pricing",
    element: <Pricing />
  },
  {
    path: "/profile",
    element: <Profile />,
    children: [
      {
        path: 'editprofile',
        element: <EditProfile />
      },
      {
        path: 'security',
        element: <Security />
      },
      {
        path: 'events',
        element: <Events />
      },
      {
        path: 'notifications',
        element: <Notifications />
      },
      {
        path: 'help',
        element: <Help />
      }
    ]
  },
  {
    path: '/privacypolicy',
    element: <PrivacyPolicy />
  },
  {
    path: '/termsofuse',
    element: <TermsOfUse />
  },
  {
    path: '/returnandrefundpolicy',
    element: <ReturnAndRefundPolicy />
  },
  {
    path: '/eulapolicy',
    element: <EULA />
  },
  {
    path: '*',
    element: <Error404 />
  },
])

const FILE_NAME = 'App.js'
const App = () => {
  console.log(`In ${FILE_NAME}`)

  return (

    <RouterProvider router={router} />

  )
};

export default App;