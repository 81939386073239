import React, { useEffect } from "react";
import "./Dashboard.css";
import { useNavigate } from "react-router-dom";
import Navbar2 from "../../Components/Navbar2/Navbar2";
import { useDispatch } from "react-redux";
import { getProfileData } from "../../redux/features/Profile/profileActions";

const Dashboard = () => {
	const navigate = useNavigate();

	useEffect(() => {
		const user = localStorage.getItem("user");
		const token = localStorage.getItem("token");
		if (!user || !token) {
			navigate("/");
		}
		// eslint-disable-next-line
	}, []);

	useEffect(() => {
		if (!localStorage.getItem("token") && !localStorage.getItem("user")) {
			navigate("/login", { replace: true });
		}
		// eslint-disable-next-line
	}, []);

	const dispatch = useDispatch();

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			const { email, contact } = user;
			if (email && contact) {
				dispatch(getProfileData({ email }));
			} else if (email) {
				dispatch(getProfileData({ email }));
			} else if (contact) {
				dispatch(getProfileData({ contact }));
			}
		}
		// eslint-disable-next-line
	}, []);

	return (
		<div className='Dashboard h-screen bg-[#f4f4f4] '>
			<Navbar2 />
			<h1 className='mb-6 mt-5 text-center'>Welcome to User Dashboard</h1>
		</div>
	);
};

export default Dashboard;
