import React, { useEffect, useState } from "react";
import PageTitle from "../../../Components/PageTitle/pagetitle";
import SignupForm from "./SignupForm/SignupForm";
import SignupEmailOTP from "./SignupEmailOTP/SignupEmailOTP";
import SignupPhoneOTP from "./SignupPhoneOTP/SignupPhoneOTP";
import { useNavigate } from "react-router-dom";

const TITLE = "Sign Up";
const Signup = () => {
	const navigate = useNavigate();
	const [signupComponents, setSignupComponents] = useState({
		signupForm: true,
		signupEmailOTP: false,
		signupPhoneOTP: false,
	});

	const setSignupForm = () => {
		setSignupComponents({
			signupForm: true,
			signupEmailOTP: false,
			signupPhoneOTP: false,
		});
	};

	const setEmailOTP = () => {
		setSignupComponents({
			signupForm: false,
			signupEmailOTP: true,
			signupPhoneOTP: false,
		});
	};
	const setPhoneOTP = () => {
		setSignupComponents({
			signupForm: false,
			signupEmailOTP: false,
			signupPhoneOTP: true,
		});
	};

	useEffect(() => {
		if (localStorage.getItem("token") && localStorage.getItem("user")) {
			navigate("/", { replace: true });
		}
	}, []);

	return (
		<>
			<PageTitle title={TITLE} />
			{signupComponents.signupForm && (
				<SignupForm
					setSignupForm={setSignupForm}
					setEmailOTP={setEmailOTP}
					setPhoneOTP={setPhoneOTP}
				/>
			)}
			{signupComponents.signupEmailOTP && <SignupEmailOTP />}
			{signupComponents.signupPhoneOTP && <SignupPhoneOTP />}
		</>
	);
};

export default Signup;
