import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "../../../utils/AxiosInstance";
import { setUserDataLocalStorage } from "../../../utils/setLocalStorage";

export const loginAuthStatus = createAsyncThunk(
   "loginAuthStatus",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/authstatus'
      try {
         let res = await AxiosInstance.post(URL, data)
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)

export const loginWithEmailPassword = createAsyncThunk(
   "loginWithEmailPassword",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/emailsignin'
      try {
         let res = await AxiosInstance.post(URL, data)
         setUserDataLocalStorage(res.data.user, res)
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)

export const loginWithMobilePassword = createAsyncThunk(
   "loginWithMobilePassword",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/mobilesignin'
      try {
         let res = await AxiosInstance.post(URL, data)
         setUserDataLocalStorage(res.data.user, res)
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)

export const loginWithEmailOTP = createAsyncThunk(
   "loginWithEmailOTP",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/emailsendotp'
      try {
         let res = await AxiosInstance.post(URL, data)
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)

export const verifyWithEmailOTP = createAsyncThunk(
   "verifyWithEmailOTP",
   async (data, { rejectWithValue }) => {
      const { email, otp, token } = data
      const URL = '/auth/emailverifyotp'
      try {
         let res = await AxiosInstance.post(URL, { email, otp }, {
            headers: {
               token: token
            }
         })
         setUserDataLocalStorage(res.data.user, res)
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log("emailverifyotp Err :", error);
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)

export const loginWithPhoneOTP = createAsyncThunk(
   "loginWithPhoneOTP",
   async (data, { rejectWithValue }) => {
      const URL = '/auth/getotp'
      try {
         let res = await AxiosInstance.post(URL, data)
         console.log(res);
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log("error:", error);
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)

export const verifyWithPhoneOTP = createAsyncThunk(
   "verifyWithPhoneOTP",
   async (data, { rejectWithValue }) => {
      const { phone, otp, token } = data
      const URL = '/auth/verifyotp'
      console.log("act data", data);
      try {
         let res = await AxiosInstance.post(URL, { phone, otp }, {
            headers: {
               token: token
            }
         })
         console.log(res);
         setUserDataLocalStorage(res.data.user, res)
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log("error:", error);
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)