import React from "react";
import Navbar from "../Navbar/Navbar";
import ERROR from "./assets/error404.svg";
import "./style.css";
import { useNavigate } from "react-router-dom";
import Footer from "../Footer/Footer";

const Error404 = () => {
	const navigate = useNavigate();
	return (
		<div className='notfound-page'>
			<Navbar />

			<div className='notfound-div flex items-center justify-center flex-col  '>
				<div className='notfound-content flex flex-col items-center justify-center gap-3 mt-28'>
					<p className='text-6xl font-extrabold text text-heading text-center md:text-9xl'>
						404
					</p>
					<p className='font-bold text-description text-center hidden md:block'>
						Sorry, this page ent on a solo journey to the desert and
						got lost in the sand dunes.
					</p>
					<p className='md:hidden font-bold text-description text-center '>
						404 - Page lost in the desert
					</p>
					<button
						className='bg-dark-purple py-2 px-7 text-white rounded mt-4 hover:bg-mid-purple'
						onClick={() => navigate("/")}
					>
						Back to Home
					</button>
				</div>
				<div className='notfound-image-div -mt-[30rem] mb-6'>
					<img
						src={ERROR}
						alt='error 404'
						className='notfound-image'
					/>
				</div>
				<Footer />
			</div>
		</div>
	);
};

export default Error404;
