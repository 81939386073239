import React, { useEffect, useState } from "react";
import "./LoginPassword.css";
import PageTitle from "../../../../Components/PageTitle/pagetitle";
import {
	Button,
	Divider,
	FormControl,
	IconButton,
	InputAdornment,
	InputLabel,
	OutlinedInput,
} from "@mui/material";
import { Visibility, VisibilityOff } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import {
	loginWithEmailPassword,
	loginWithMobilePassword,
} from "../../../../redux/features/auth/loginActions";
import { useNavigate } from "react-router-dom";

const TITLE = "Login Password";

const LoginPassword = () => {
	const [showPassword, setShowPassword] = useState(false);
	const [password, setPassword] = useState("");

	const dispatch = useDispatch();
	const navigate = useNavigate();
	const {
		success,
		status,
		message,
		userInfo: { email, contact, password: statePass },
		loggedBy: { phone },
	} = useSelector((state) => state.login);

	const handleClickShowPassword = () => {
		setShowPassword(!showPassword);
	};

	const handleMouseDownPassword = (event) => {
		event.preventDefault();
	};
	const validatePassword = (data) => {
		if (data.password === "") {
			toast.error("Password Cannot be empty");
			return false;
		}
		return true;
	};

	const handlePasswordSubmit = (e) => {
		e.preventDefault();
		// Email and statePass are present
		if (email && statePass) {
			const data = { email, password };
			if (!validatePassword(data)) return;
			console.log(data);
			dispatch(loginWithEmailPassword(data));
		} else if (contact && statePass) {
			const data = { phone, password };
			if (!validatePassword(data)) return;
			console.log(data);
			dispatch(loginWithMobilePassword(data));
		}
	};

	useEffect(() => {
		if (status) {
			if (status === 404 || status === 401) {
				toast.error(`${message} Please Try Again`);
			}
			if (status === 200 && success) {
				toast.success("Login Success");
				setTimeout(() => {
					navigate("/dashboard");
				}, 350);
			}
		}
		// eslint-disable-next-line
	}, [status]);

	return (
		<>
			<PageTitle title={TITLE} />
			<Toaster />
			<div className='login-password-wrapper relative'>
				<form
					className='login-password-form relative'
					onSubmit={handlePasswordSubmit}
				>
					<div className='flex my-5 align-center justify-center relative'>
						<svg
							width='11'
							height='20'
							viewBox='0 0 11 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className='mt-2 cursor-pointer'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.6201 0.98957C10.1301 0.49957 9.34006 0.49957 8.85006 0.98957L0.540059 9.29957C0.150059 9.68957 0.150059 10.3196 0.540059 10.7096L8.85006 19.0196C9.34006 19.5096 10.1301 19.5096 10.6201 19.0196C11.1101 18.5296 11.1101 17.7396 10.6201 17.2496L3.38006 9.99957L10.6301 2.74957C11.1101 2.26957 11.1101 1.46957 10.6201 0.98957Z'
								fill='#373737'
							/>
						</svg>
						<h1 className='text-2xl font-semibold relative mx-auto'>
							Log in
						</h1>
					</div>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
						}}
					/>
					<p className='text-heading font-semibold text-left mt-6'>
						Enter your Password
					</p>
					<div className='my-6'>
						<FormControl fullWidth variant='outlined'>
							<InputLabel htmlFor='outlined-adornment-password'>
								Password
							</InputLabel>
							<OutlinedInput
								id='outlined-adornment-password'
								type={showPassword ? "text" : "password"}
								className='login-password'
								autoFocus
								value={password}
								onChange={(e) => setPassword(e.target.value)}
								sx={{
									borderRadius: "20px",
									"& .MuiOutlinedInput-root": {
										"&.Mui-focused fieldset": {
											borderColor: "var(--mid-purple)",
										},
									},
									"& label.Mui-focused": {
										color: "var(--mid-purple)",
									},
								}}
								endAdornment={
									<InputAdornment position='end'>
										<IconButton
											aria-label='toggle password visibility'
											onClick={handleClickShowPassword}
											onMouseDown={
												handleMouseDownPassword
											}
											edge='end'
										>
											{showPassword ? (
												<VisibilityOff />
											) : (
												<Visibility />
											)}
										</IconButton>
									</InputAdornment>
								}
								label='Password'
							/>
						</FormControl>
					</div>

					<Button
						variant='contained'
						fullWidth
						type='submit'
						style={{
							borderRadius: 20,
							padding: "15px 20px",
							color: "white",
							backgroundColor: "var(--dark-purple)",
							textTransform: "Capitalize",
						}}
					>
						Login
					</Button>
					<p className='text-right mt-2 mb-16'>
						<a
							href='/login/password'
							className='text-mid-purple text-sm'
						>
							Forget Password ?
						</a>
					</p>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
						}}
					/>
					<p className='text-center py-5'>
						<a className='text-mid-purple' href='/signup'>
							Don't have a account? Sign Up
						</a>
					</p>
				</form>
			</div>
		</>
	);
};

export default LoginPassword;
