import React from "react";
import "./Notifications.css";

const Notifications = () => {
	return (
		<div>
			<h1>Notifications</h1>
		</div>
	);
};

export default Notifications;
