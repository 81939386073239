import React, { useEffect, useState } from "react";
import "./SignupPhoneOTP.css";
import PageTitle from "../../../../Components/PageTitle/pagetitle";
import { Button, Divider } from "@mui/material";
import OTPInput from "react-otp-input";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
	signUpUserWithPhone,
	verifySignUpUserWithPhone,
} from "../../../../redux/features/auth/signUpActions";
import { Toaster, toast } from "react-hot-toast";

const TITLE = "Phone OTP";

const SignupPhoneOTP = () => {
	const navigate = useNavigate();
	const [otp, setOtp] = useState("");
	const [countdown, setCountdown] = useState(40);
	const [resendClicked, setResendClicked] = useState(false);
	const {
		status,
		success,
		userInfo: { number, referral },
		token,
		error,
	} = useSelector((state) => state.signup);
	const dispatch = useDispatch();

	const phoneOtpVerifyHandler = (e) => {
		e.preventDefault();
		const data = {
			phone: number,
			token,
			otp,
		};
		dispatch(verifySignUpUserWithPhone(data))
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
	};
	const resendHandler = () => {
		setCountdown(40);
		setResendClicked(true);
		const data = { phone: number, referral };

		if (data.phone) {
			dispatch(signUpUserWithPhone(data)).then((res) => {
				if (res.payload.status === 200) {
					toast.success(
						`OTP has been resent to +${data.phone && data.phone}`
					);
				}
			});
		}
	};

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);
		return () => clearInterval(intervalId);
	}, [resendClicked]);

	useEffect(() => {
		if (success && status === 200) {
			navigate("/dashboard");
		}
		if (error === true && status === 403) {
			toast.error("Token Expired or invalid Token");
		}
		// eslint-disable-next-line
	}, [status, success]);

	return (
		<>
			<PageTitle title={TITLE} />
			<Toaster />
			<div className='signup-otp-wrapper relative'>
				<form className='signup-otp-form relative'>
					<div className='flex my-5 align-center justify-center relative'>
						<svg
							width='11'
							height='20'
							viewBox='0 0 11 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className='mt-2 cursor-pointer'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.6201 0.98957C10.1301 0.49957 9.34006 0.49957 8.85006 0.98957L0.540059 9.29957C0.150059 9.68957 0.150059 10.3196 0.540059 10.7096L8.85006 19.0196C9.34006 19.5096 10.1301 19.5096 10.6201 19.0196C11.1101 18.5296 11.1101 17.7396 10.6201 17.2496L3.38006 9.99957L10.6301 2.74957C11.1101 2.26957 11.1101 1.46957 10.6201 0.98957Z'
								fill='#373737'
							/>
						</svg>
						<h1 className='text-2xl font-semibold relative mx-auto'>
							Sign Up
						</h1>
					</div>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
						}}
					/>
					<p className='text-heading font-semibold text-left mt-6'>
						Enter the 6-digit OTP received on +{number} via WhatsApp
					</p>
					<div className='my-6 loginotp-div mx-3  flex items-center justify-center'>
						<OTPInput
							value={otp}
							onChange={setOtp}
							containerStyle='signup-otp'
							numInputs={6}
							inputType='number'
							renderInput={(props) => <input {...props} />}
							inputStyle={{
								fontSize: 20,
								height: 47,
								width: 47,
								borderRadius: 15,
							}}
							shouldAutoFocus
						/>
					</div>
					<Button
						variant='contained'
						fullWidth
						onClick={phoneOtpVerifyHandler}
						style={{
							borderRadius: 20,
							padding: "15px 20px",
							color: "white",
							backgroundColor: "var(--dark-purple)",
							textTransform: "Capitalize",
						}}
					>
						Login
					</Button>

					<p className='text-center my-5 text-sm '>
						Didn't Receive the OTP ?
						{countdown > 0 ? (
							<span className='text-sm ml-2'>
								Resend in{" "}
								<span className='font-semibold'>
									{countdown}
								</span>
							</span>
						) : (
							<span
								className='text-mid-purple text-sm ml-2 cursor-pointer'
								onClick={resendHandler}
							>
								Resend
							</span>
						)}
					</p>

					<p className='text-description text-xs font-semibold text-center my-4'>
						By signing up, I agree to the{" "}
						<a href='/termsofuse' className='text-mid-purple'>
							Terms of use
						</a>
						{"\u00A0"}and{"\u00A0"}
						<a href='/privacypolicy' className='text-mid-purple'>
							Privacy Policy
						</a>
					</p>

					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
							marginTop: "3rem",
						}}
					/>
				</form>
			</div>
		</>
	);
};

export default SignupPhoneOTP;
