import React, { useEffect, useState } from "react";
import "./EmailOTPModal.css";
import { useDispatch, useSelector } from "react-redux";
import { Toaster, toast } from "react-hot-toast";
import { Button, Divider } from "@mui/material";
import OTPInput from "react-otp-input";
import {
	sendProfileEmailOTP,
	verifyAddEmailOTP,
} from "../../../../../../redux/features/Profile/profileActions";
const EmailOTPModal = ({ handleClose }) => {
	const [otp, setOtp] = useState("");
	const [countdown, setCountdown] = useState(40);
	const [resendClicked, setResendClicked] = useState(false);
	const { success, loading, error, sessionId, addCredential } = useSelector(
		(state) => state.profile
	);
	const dispatch = useDispatch();

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);
		if (countdown === 0) {
			clearInterval(intervalId);
		}
		return () => clearInterval(intervalId);
	}, [resendClicked, countdown]);

	const emailOtpVerifyHandler = (e) => {
		e.preventDefault();
		const data = { addCredential, otp, sessionId };
		dispatch(verifyAddEmailOTP(data)).then((res) => {
			if (res.payload.response.status === 200) {
				toast.success(`Your Email Has been Updated`);
			}
			handleClose();
		});
	};

	const resendHandler = () => {
		setCountdown(40);
		setResendClicked(true);
		const data = { addCredential };
		dispatch(sendProfileEmailOTP(data)).then((res) => {
			if (res.payload.response.status === 200) {
				toast.success(
					`OTP resent to ${
						addCredential.email && addCredential.email
					}`
				);
			}
		});
	};

	return (
		<div className='email-otp-modal-div absolute'>
			<Toaster />
			<form
				className='email-otp-modal relative'
				onSubmit={emailOtpVerifyHandler}
			>
				<div className='flex my-5 align-center justify-center relative'>
					<svg
						width='11'
						height='20'
						viewBox='0 0 11 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						className='mt-2 cursor-pointer'
						onClick={handleClose}
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10.6201 0.98957C10.1301 0.49957 9.34006 0.49957 8.85006 0.98957L0.540059 9.29957C0.150059 9.68957 0.150059 10.3196 0.540059 10.7096L8.85006 19.0196C9.34006 19.5096 10.1301 19.5096 10.6201 19.0196C11.1101 18.5296 11.1101 17.7396 10.6201 17.2496L3.38006 9.99957L10.6301 2.74957C11.1101 2.26957 11.1101 1.46957 10.6201 0.98957Z'
							fill='#373737'
						/>
					</svg>
					<h1 className='text-2xl font-semibold relative mx-auto'>
						Verify Email
					</h1>
				</div>
				<Divider
					style={{
						backgroundColor: "#eeeeee",
						height: "4px",
						border: "none",
						marginLeft: "-2rem",
						marginRight: "-2rem",
					}}
				/>
				<p className='text-heading font-semibold text-left mt-6'>
					Enter the 6-digit OTP received on
				</p>
				<div className='my-6 loginotp-div mx-3  flex items-center justify-center'>
					<OTPInput
						value={otp}
						onChange={setOtp}
						containerStyle='profile-email-otp'
						numInputs={6}
						inputType='number'
						renderInput={(props) => <input {...props} />}
						inputStyle={{
							fontSize: 20,
							height: 47,
							width: 47,
							borderRadius: 15,
						}}
						shouldAutoFocus
					/>
				</div>
				<Button
					variant='contained'
					fullWidth
					type='submit'
					style={{
						borderRadius: 20,
						padding: "15px 20px",
						color: "white",
						backgroundColor: "var(--dark-purple)",
						textTransform: "Capitalize",
					}}
				>
					Verify
				</Button>

				<p className='text-center my-5 text-sm '>
					Didn't Receive the OTP ?
					{countdown > 0 ? (
						<span className='text-sm ml-2'>
							Resend in{" "}
							<span className='font-semibold'>{countdown}</span>
						</span>
					) : (
						<span
							className='text-mid-purple text-sm ml-2 cursor-pointer'
							onClick={resendHandler}
						>
							Resend
						</span>
					)}
				</p>

				<Divider
					style={{
						backgroundColor: "#eeeeee",
						height: "4px",
						border: "none",
						marginLeft: "-2rem",
						marginRight: "-2rem",
						marginTop: "4.5rem",
					}}
				/>
			</form>
		</div>
	);
};

export default EmailOTPModal;
