import React, { useEffect, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom";

/**
 * STYLES
 */
import "./Profile.css";

/**
 * COMPONENTS
 */
import Navbar2 from "../../Components/Navbar2/Navbar2";

/**
 * ASSETS
 */
import LOGO from "../../Assets/oralogopurple.svg";
import EditProfile from "./assets/edit-profile.svg";
import EditProfileSelected from "./assets/edit-profile-selected.svg";
import Security from "./assets/security.svg";
import SecuritySelected from "./assets/security-selected.svg";
import MyEvents from "./assets/my-events.svg";
import MyEventsSelected from "./assets/my-events-selected.svg";
import Notifications from "./assets/notifications.svg";
import NotificationsSelected from "./assets/notifications-selected.svg";
import Help from "./assets/help.svg";
import HelpSelected from "./assets/help-selected.svg";
import Logout from "./assets/logout.svg";

/**
 * FUNCTIONS
 */
import { logout } from "../../utils/logout";

const Profile = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const profileItems = [
    {
      name: "Edit Profile",
      path: "/profile/editprofile",
      icon: EditProfile,
      iconSelected: EditProfileSelected,
    },
    {
      name: "Security",
      path: "/profile/security",
      icon: Security,
      iconSelected: SecuritySelected,
    },
    {
      name: "Events",
      path: "/profile/events",
      icon: MyEvents,
      iconSelected: MyEventsSelected,
    },
    {
      name: "Notification",
      path: "/profile/notifications",
      icon: Notifications,
      iconSelected: NotificationsSelected,
    },
    {
      name: "Help",
      path: "/profile/help",
      icon: Help,
      iconSelected: HelpSelected,
    },
    { name: "Logout", path: "", icon: Logout, iconSelected: Logout },
  ];

  const [selected, setSelected] = useState(0);

  useEffect(() => {
    const selectedIndex = profileItems.findIndex(
      (item) => item.path === pathname
    );
    setSelected(selectedIndex);

    if (pathname === "/profile") {
      navigate("/profile/editprofile");
    }
    // eslint-disable-next-line
  }, [pathname, profileItems]);

  useEffect(() => {
    if (!localStorage.getItem("token") && !localStorage.getItem("user")) {
      navigate("/login", { replace: true });
    }
    // eslint-disable-next-line
  }, []);
  const logoutHandler = () => {
    logout();
    navigate("/");
  };

  return (
    <div className="bg-[#f4f4f4]">
      <Navbar2 />

      <div className="Profile flex">
        <div className="profile-nav border bg-white">
          <div className="profile-logo" onClick={() => navigate("/dashboard")} >
            <img src={LOGO} alt="Logo" style={{ height: "40px" }} />
          </div>
          <div className="profile-items">
            {profileItems.map((item, idx) => (
              <Link
                to={item.path}
                key={idx}
                onClick={() =>
                  idx === profileItems.length - 1
                    ? logoutHandler()
                    : setSelected(idx)
                }
                className={`${
                  selected === idx ? "profile-selected" : ""
                } text-sm font-semibold flex gap-5 ${
                  idx === profileItems.length - 1 ? "list-logout" : ""
                }`}
              >
                {selected === idx ? (
                  <img
                    src={item.iconSelected}
                    className="list-icon"
                    alt="ico"
                    style={{ width: "20px", height: "20px" }}
                  />
                ) : (
                  <img
                    src={item.icon}
                    className="list-icon"
                    alt="ico"
                    style={{ width: "20px", height: "20px" }}
                  />
                )}
                {item.name}
              </Link>
            ))}
          </div>
        </div>

        <div className="profile-content p-5">
          <Outlet />
        </div>
      </div>
    </div>
  );
};

export default Profile;
