import React, { useEffect, useState } from "react";
import LoginStatus from "./LoginStatus/LoginStatus";
import LoginPassword from "./LoginPassword/LoginPassword";
import LoginEmailOTP from "./LoginEmailOTP/LoginEmailOTP";
import LoginPhoneOTP from "./LoginPhoneOTP/LoginPhoneOTP";
import { useNavigate } from "react-router-dom";

const Login = () => {
	const navigate = useNavigate();
	const [loginComponents, setLoginComponents] = useState({
		loginStatus: true,
		loginPassword: false,
		loginEmailOTP: false,
		loginPhoneOTP: false,
	});

	const setLogin = () => {
		setLoginComponents({
			loginStatus: true,
			loginPassword: false,
			loginEmailOTP: false,
			loginPhoneOTP: false,
		});
	};
	const setPassword = () => {
		setLoginComponents({
			loginStatus: false,
			loginPassword: true,
			loginEmailOTP: false,
			loginPhoneOTP: false,
		});
	};

	const setEmailOTP = () => {
		setLoginComponents({
			loginStatus: false,
			loginPassword: false,
			loginEmailOTP: true,
			loginPhoneOTP: false,
		});
	};
	const setPhoneOTP = () => {
		setLoginComponents({
			loginStatus: false,
			loginPassword: false,
			loginEmailOTP: false,
			loginPhoneOTP: true,
		});
	};
	useEffect(() => {
		if (localStorage.getItem("token") && localStorage.getItem("user")) {
			navigate("/", { replace: true });
		}
	}, []);
	return (
		<>
			{loginComponents.loginStatus && (
				<LoginStatus
					setPassword={setPassword}
					setEmailOTP={setEmailOTP}
					setPhoneOTP={setPhoneOTP}
					setLogin={setLogin}
				/>
			)}
			{loginComponents.loginPassword && <LoginPassword />}
			{loginComponents.loginEmailOTP && <LoginEmailOTP />}
			{loginComponents.loginPhoneOTP && <LoginPhoneOTP />}
		</>
	);
};

export default Login;
