import { configureStore } from '@reduxjs/toolkit'
import signupReducer from './features/auth/signupSlice'
import loginReducer from './features/auth/loginSlice'
import pricingReducer from './features/Pricing/pricingSlice'
import profileReducer from './features/Profile/profileSlice'
import eventReducer from './features/event/eventSlice'

export const store = configureStore({
   reducer: {
      signup: signupReducer,
      login: loginReducer,
      pricing: pricingReducer,
      profile: profileReducer,
      event: eventReducer
   }
})