import { createSlice } from "@reduxjs/toolkit";
import { gerEventReference } from "./eventActions";

const initialState = {
   loading: null,
   success: null,
   status: null,
   error: null,
   message: null,
   eventReference: "",
}
const eventSlice = createSlice({
   name: "eventSlice",
   initialState,
   reducers: {},
   extraReducers: (builder) => {
      // get Event Reference
      builder.addCase(gerEventReference.pending, (state) => {
         state.loading = true
      })
      builder.addCase(gerEventReference.fulfilled, (state, { payload }) => {
         console.log(payload);
         state.loading = false
         state.eventReference = payload?.response?.data
         state.status = payload?.response?.status
         state.success = true
         state.message = ""
      })
      builder.addCase(gerEventReference.rejected, (state, { payload }) => {
         console.log(payload);
         state.loading = false;
         state.error = true
         state.status = payload?.status
         state.message = payload?.data?.message
      })
   }
})

export default eventSlice.reducer