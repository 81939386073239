import React, { useEffect, useState } from "react";
import "./EditProfile.css";
import { Button, InputAdornment, InputLabel, TextField } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
	getProfileData,
	updateProfile,
} from "../../../../redux/features/Profile/profileActions";
import EditIcon from "@mui/icons-material/Edit";
import EmailModal from "./Modals/EmailModal/EmailModal";
import EmailOTPModal from "./Modals/EmailOTPModal/EmailOTPModal";
import PhoneModal from "./Modals/PhoneModal/PhoneModal";
import PhoneOTPModal from "./Modals/PhoneOTPModal/PhoneOTPModal";
import { useNavigate } from "react-router-dom";

const EditProfile = () => {
	const navigate = useNavigate();
	const state = useSelector((state) => state.profile);
	const { success, data } = state;

	const [name, setName] = useState(data?.name || "");
	const [email, setEmail] = useState(data?.email || "");
	const [phone, setPhone] = useState(data?.contact || "");
	const [avatar, setAvatar] = useState(data?.avatar || "");
	const dispatch = useDispatch();

	useEffect(() => {
		setName(data?.name || "");
		setEmail(data?.email || "");
		setPhone(data?.contact || "");
		setAvatar(data?.avatar || "");
	}, [data, success]);

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			const { email, contact } = user;
			if (email && contact) {
				dispatch(getProfileData({ email }));
			} else if (email) {
				dispatch(getProfileData({ email }));
			} else if (contact) {
				dispatch(getProfileData({ phone: contact.substring(2) }));
			}
		}
		// eslint-disable-next-line
	}, []);

	const [emailModal, setEmailModal] = useState({
		emailModal: false,
		emailOTPModal: false,
	});
	const [phoneModal, setPhoneModal] = useState({
		phoneModal: false,
		phoneOTPModal: false,
	});

	const avatarHandler = (e) => {
		console.log(e.target.files);
		const file = e.target.files[0];
		if (file) {
			const reader = new FileReader();
			reader.onloadend = () => {
				const base64String = reader.result;
				console.log(base64String.split(",")[1]);
				setAvatar(base64String.split(",")[1]);
			};
			reader.readAsDataURL(file);
		}
	};

	const saveHandler = () => {
		const { _id } = JSON.parse(localStorage.getItem("user"));
		let data = { id: _id, name };
		if (avatar) {
			data = { ...data, avatar };
		}
		dispatch(updateProfile(data));
	};

	const updateEmail = () => {
		setEmailModal({ emailModal: true, emailOTPModal: false });
	};
	const updatePhone = () => {
		console.log("add phone clicked");
		setPhoneModal({ phoneModal: true, phoneOTPModal: false });
	};
	const securityHandler = () => {
		navigate("/profile/security");
	};

	return (
		<div className='EditProfile mt-2'>
		  <div className='flex items-center justify-between w-[650px]'>
			<h1 className='text-lg font-semibold text-[#2B2B2B]'>
			  Edit Profile
			</h1>
			<div className='avatar-div relative'>
			  {data?.avatar === "undefined" || data?.avatar === null ? (
				<div className='no-avatar'>
				  {data?.name && data?.name?.split(" ")[0][0]}
				  {data?.name && data?.name?.split(" ")[1][0]}
				</div>
			  ) : (
				<img
				  src={`data:image/png;base64,${avatar}`}
				  alt='avatar'
				  className='border rounded-full'
				/>
			  )}
			  <label
				htmlFor='avatarInput'
				className='avatar-edit border rounded-full cursor-pointer shadow-md'
			  >
				<EditIcon style={{ padding: "2px" }} />
				<input
				  id='avatarInput'
				  type='file'
				  accept='image/x-png,image/gif,image/jpeg'
				  hidden
				  onChange={avatarHandler}
				/>
			  </label>
			</div>
		  </div>
		  <form className='editprofile-form-div flex flex-col gap-6 mt-6'>
			<div className='flex flex-col gap-2'>
			  <InputLabel
				htmlFor='fullname'
				className='text-base font-medium text-[#2B2B2B]'
			  >
				Full Name
			  </InputLabel>
			  <TextField
				id='fullname'
				value={name}
				onChange={(e) => setName(e.target.value)}
				placeholder='Enter Your Full Name'
				variant='outlined'
				className='editprofile-form'
				size='small'
				sx={{
				  border: "none",
				  "& fieldset": { border: "none" },
				}}
			  />
			</div>
			<div className='flex flex-col gap-2'>
			  <div className='flex items-center justify-between'>
				<InputLabel
				  htmlFor='email'
				  className='text-base font-medium text-[#2B2B2B]'
				>
				  Email address
				</InputLabel>
				{!email && (
				  <p
					className='text-mid-purple font-normal text-base underline cursor-pointer'
					onClick={updateEmail}
				  >
					Add Email Address
				  </p>
				)}
			  </div>
	  
			  <TextField
				id='email'
				value={email}
				onChange={(e) => setEmail(e.target.value)}
				placeholder='Enter Your Email'
				variant='outlined'
				className='editprofile-form'
				size='small'
				sx={{
				  border: "none",
				  "& fieldset": { border: "none" },
				}}
				disabled={data?.email ? true : false}
				InputProps={{
				  endAdornment: (
					<InputAdornment position='start'>
					  {email && <CheckCircleIcon style={{ fontSize: "20px", color: "#23C552" }} />}
					</InputAdornment>
				  ),
				}}
			  />
			</div>
			<div className='flex flex-col gap-2'>
			  <div className='flex justify-between'>
				<InputLabel
				  htmlFor='contactNo'
				  className='text-base font-medium text-[#2B2B2B]'
				>
				  Contact Number
				</InputLabel>
				{!phone && (
				  <p
					className='text-mid-purple font-normal text-base underline cursor-pointer'
					onClick={updatePhone}
				  >
					Add Contact Number
				  </p>
				)}
			  </div>
	  
			  <TextField
				id='contactNo'
				value={phone && `+${phone.slice(0, 2)} ${phone.slice(2)}`}
				onChange={(e) => setPhone(e.target.value)}
				placeholder='Enter Your Contact Number'
				variant='outlined'
				className='editprofile-form'
				size='small'
				sx={{
				  border: "none",
				  "& fieldset": { border: "none" },
				}}
				disabled={phone ? true : false}
				InputProps={{
				  endAdornment: (
					<InputAdornment position='start'>
					  {phone && <CheckCircleIcon style={{ fontSize: "20px", color: "#23C552" }} />}
					</InputAdornment>
				  ),
				}}
			  />
			</div>
			<div>
			<Button
				variant='outlined'
				style={{
					borderRadius: "8px",
					padding: "5px 20px",
					color: "#F5F5F5",
					backgroundColor: "#FC9D33",
					textTransform: "Capitalize",
					border: "none",
					fontSize: "14px",
					fontWeight: "500",
				}}
				onClick={securityHandler}
				>
				{data?.password ? "Change Password" : "Create Password"}
			</Button>
			</div>
			<div className='flex gap-4'>
			<Button
				variant='outlined'
				style={{
					borderRadius: "8px",
					padding: "2px 20px",
					color: "#717171",
					borderColor: "#717171",
					textTransform: "Capitalize",
					fontSize: "14px",
					fontWeight: "500",
				}}
				onClick={() => navigate("/dashboard")}
				>
				Cancel
				</Button>
				<Button
					variant='contained'
					style={{
						borderRadius: "8px",
						padding: "2px 20px",
						color: "white",
						backgroundColor: "var(--dark-purple)",
						textTransform: "Capitalize",
						fontSize: "14px",
						fontWeight: "500",
					}}
					onClick={saveHandler}
				>
				Save
			</Button>
			</div>
		  </form>
		  {emailModal.emailModal && (
			<EmailModal
			  handleClose={() =>
				setEmailModal({
				  emailModal: false,
				  emailOTPModal: false,
				})
			  }
			  openEmailOtp={() =>
				setEmailModal({
				  emailModal: false,
				  emailOTPModal: true,
				})
			  }
			/>
		  )}
		  {emailModal.emailOTPModal && (
			<EmailOTPModal
			  handleClose={() =>
				setEmailModal({
				  emailModal: false,
				  emailOTPModal: false,
				})
			  }
			/>
		  )}
	  
		  {phoneModal.phoneModal && (
			<PhoneModal
			  handleClose={() =>
				setPhoneModal({
				  phoneModal: false,
				  phoneOTPModal: false,
				})
			  }
			  openPhoneOtp={() =>
				setPhoneModal({
				  phoneModal: false,
				  phoneOTPModal: true,
				})
			  }
			/>
		  )}
		  {phoneModal.phoneOTPModal && (
			<PhoneOTPModal
			  handleClose={() =>
				setPhoneModal({
				  phoneModal: false,
				  phoneOTPModal: false,
				})
			  }
			/>
		  )}
		</div>
	);
	  
};

export default EditProfile;
