import React, { useEffect } from "react";
import "./Navbar2.css";
import LOGO from "../../Assets/oralogopurple.svg";
import { InputAdornment, TextField } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import NotificationsIcon from "@mui/icons-material/Notifications";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProfileData } from "../../redux/features/Profile/profileActions";

const Navbar2 = () => {
	const navigate = useNavigate();
	const dispatch = useDispatch();

	useEffect(() => {
		const user = JSON.parse(localStorage.getItem("user"));
		if (user) {
			const { email, contact } = user;
			if (email && contact) {
				dispatch(getProfileData({ email }));
			} else if (email) {
				dispatch(getProfileData({ email }));
			} else if (contact) {
				dispatch(getProfileData({ phone: contact.substring(2) }));
			}
		}
		// eslint-disable-next-line
	}, []);

	const state = useSelector((state) => state.profile);
	const { data } = state;

	return (
		<div className='navbar2 bg-white flex items-center justify-around shadow-sm'>
		  <div className='navbar2-left flex items-center justify-center gap-16'>
			<img
			  src={LOGO}
			  alt='Logo'
			  className='h-9 cursor-pointer' // Change the height here
			  onClick={() => navigate("/dashboard")}
			/>
		  </div>
		  <div className='navbar2-right flex items-center justify-between mx-14'>
			<div>
			  <TextField
				id='outlined-search'
				label='Search Events'
				type='search'
				size='small'
				className='w-[15em] bg-[#f5f5f5] rounded-xl search-input text-sm'
				sx={{
				  "& fieldset": { border: "none" },
				  caretColor: "var(--dark-purple)",
				}}
				InputLabelProps={{
				  style: { color: "#AEAEAE" },
				}}
				InputProps={{
				  endAdornment: (
					<InputAdornment position='end'>
					  <SearchIcon
						className='bg-dark-purple text-white p-1 rounded-xl -mr-2'
						fontSize='medium'
					  />
					</InputAdornment>
				  ),
				}}
			  />
			</div>
			<div className='flex items-center gap-7'>
			  <div
				className='flex items-center bg-[#efefef] p-2 rounded-2xl text-dark-purple cursor-pointer text-sm rounded-xl' // Added rounded-xl class here
				onClick={() => navigate("/eventmanagement")}
			  >
				<AddIcon fontSize='small' />
				<p>Create an Event</p>
			  </div>
			  <div className='flex items-center bg-[#efefef] p-1.5 rounded-full text-dark-purple cursor-pointer rounded-xl'> {/* Added rounded-xl class here */}
				<NotificationsIcon fontSize='small' />
			  </div>
			  <div
				onClick={() => navigate("/profile/editprofile")}
				className='flex avatar-div2 items-center gap-2 bg-[#efefef] p-1.5 rounded-2xl w-[150px] cursor-pointer rounded-xl' // Added rounded-xl class here
			  >
				{data?.avatar === "undefined" || data?.avatar === null ? (
				  <div className='no-avatar2 text-xs'>
					{data && data?.name?.split(" ")[0][0]}
					{data && data?.name?.split(" ")[1][0]}
				  </div>
				) : (
				  <img
					src={`data:image/png;base64,${data?.avatar}`}
					alt='avatar'
					className='border rounded-lg'
					width={25}
					height={25}
				  />
				)}
				<p className='text-[#373737] truncate text-sm'>{data?.name}</p>
			  </div>
			</div>
		  </div>
		</div>
	  );
};

export default Navbar2;
