import React, { useState } from "react";
import "./PhoneModal.css";
import { Toaster, toast } from "react-hot-toast";
import { Button, Divider, TextField } from "@mui/material";
import { useDispatch } from "react-redux";
import { sendProfilePhoneOTP } from "../../../../../../redux/features/Profile/profileActions";

const PhoneModal = ({ openPhoneOtp, handleClose }) => {
	const [phone, setPhone] = useState("");
	const dispatch = useDispatch();
	const validateSignUp = (num) => {
		if (num.phone === "") {
			toast.error("Phone Number Cannot be empty");
			return false;
		} else if (num.phone.length > 1 && num.phone.length < 10) {
			toast.error("Phone Number Invalid");
			return false;
		} else if (num.phone.length > 10) {
			toast.error("Phone Number Invalid");
			return false;
		}
		return true;
	};
	const verifyHandler = (e) => {
		e.preventDefault();
		const data = { phone };
		if (!validateSignUp(data)) return;
		dispatch(sendProfilePhoneOTP(data)).then((res) => {
			if (res?.payload?.response?.status === 200) {
				toast.success(`OTP Sent Successfullt ${phone}`);
				setTimeout(() => {
					openPhoneOtp();
				}, 10);
			}
		});
	};
	return (
		<div className='phone-modal-div absolute'>
			<Toaster />
			<form className='phone-modal relative' onSubmit={verifyHandler}>
				<div className='flex my-5 align-center justify-center relative'>
					<svg
						width='11'
						height='20'
						viewBox='0 0 11 20'
						fill='none'
						xmlns='http://www.w3.org/2000/svg'
						className='mt-2 cursor-pointer'
						onClick={handleClose}
					>
						<path
							fillRule='evenodd'
							clipRule='evenodd'
							d='M10.6201 0.98957C10.1301 0.49957 9.34006 0.49957 8.85006 0.98957L0.540059 9.29957C0.150059 9.68957 0.150059 10.3196 0.540059 10.7096L8.85006 19.0196C9.34006 19.5096 10.1301 19.5096 10.6201 19.0196C11.1101 18.5296 11.1101 17.7396 10.6201 17.2496L3.38006 9.99957L10.6301 2.74957C11.1101 2.26957 11.1101 1.46957 10.6201 0.98957Z'
							fill='#373737'
						/>
					</svg>
					<h1 className='text-2xl font-semibold relative mx-auto'>
						Add Contact Number
					</h1>
				</div>
				<Divider
					style={{
						backgroundColor: "#eeeeee",
						height: "4px",
						border: "none",
						marginLeft: "-2rem",
						marginRight: "-2rem",
					}}
				/>
				<div className='text-[#373737] font-semibold text-xl mt-8'>
					Enter Contact Number
				</div>
				<div className='mt-4 mb-4'>
					<TextField
						id='outlined-required'
						label='Enter Your Phone'
						className='phone-modal-input my-10'
						value={phone}
						onChange={(e) => setPhone(e.target.value)}
						fullWidth
						autoFocus={true}
						InputProps={{
							style: { borderRadius: 20 },
						}}
					/>
				</div>

				<Button
					variant='contained'
					fullWidth
					className='emailphone-btn mt-4'
					type='submit'
					style={{
						borderRadius: 20,
						padding: "15px 20px",
						color: "white",
						backgroundColor: "var(--dark-purple)",
						textTransform: "Capitalize",
					}}
				>
					Verify
				</Button>

				<Divider
					style={{
						backgroundColor: "#eeeeee",
						height: "4px",
						border: "none",
						marginLeft: "-2rem",
						marginRight: "-2rem",
						marginTop: "6.5rem",
					}}
				/>
			</form>
		</div>
	);
};

export default PhoneModal;
