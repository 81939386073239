import React from "react";
import "./Events.css";

const Events = () => {
	return (
		<div>
			<h1>Events</h1>
		</div>
	);
};

export default Events;
