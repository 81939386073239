import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "../../../utils/AxiosInstance";


export const gerEventReference = createAsyncThunk(
   "gerEventReference",
   async (_, { rejectWithValue }) => {
      const URL = "/event/types"
      const token = JSON.parse(localStorage.getItem("token"))
      try {
         let res = await AxiosInstance.get(URL, {
            headers: {
               Authorization: token
            }
         })
         console.log(res);
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)