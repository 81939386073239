import React, { useEffect, useState } from "react";
import "./LoginPhoneOTP.css";
import PageTitle from "../../../../Components/PageTitle/pagetitle";
import { Button, Divider } from "@mui/material";
import OtpInput from "react-otp-input";
import {
	loginWithPhoneOTP,
	verifyWithPhoneOTP,
} from "../../../../redux/features/auth/loginActions";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { toast, Toaster } from "react-hot-toast";

const TITLE = "Login OTP";

const LoginPhoneOTP = () => {
	const navigate = useNavigate();
	const [otp, setOtp] = useState("");
	const [countdown, setCountdown] = useState(40);
	const [resendClicked, setResendClicked] = useState(false);

	const dispatch = useDispatch();
	const {
		token,
		loggedBy: { phone },
		status,
		success,
		error,
		message,
	} = useSelector((state) => state.login);

	useEffect(() => {
		const intervalId = setInterval(() => {
			setCountdown((prev) => prev - 1);
		}, 1000);
		return () => clearInterval(intervalId);
	}, [resendClicked]);

	const phoneOtpVerifyHandler = (e) => {
		e.preventDefault();
		dispatch(verifyWithPhoneOTP({ phone, otp, token }))
			.then((res) => console.log(res))
			.catch((err) => console.log(err));
	};

	useEffect(() => {
		if (status === 200 && success && token) {
			navigate("/dashboard");
		}
		if (error === true && status === 403) {
			toast.error("Token Expired or invalid Token");
		}
		// eslint-disable-next-line
	}, [status, success]);

	const resendHandler = () => {
		setCountdown(40);
		setResendClicked(true);
		const data = { phone };
		console.log("resendPhone", data);
		dispatch(loginWithPhoneOTP(data)).then((res) => {
			console.log("resendRes", res);
			if (res.payload.response.status === 200) {
				toast.success(`OTP resent to ${data.email && data.email}`);
			}
		});
	};
	return (
		<>
			<PageTitle title={TITLE} />

			<Toaster />

			<div className='login-otp-wrapper relative'>
				<form
					className='login-otp-form relative'
					onSubmit={phoneOtpVerifyHandler}
				>
					<div className='flex my-5 align-center justify-center relative'>
						<svg
							width='11'
							height='20'
							viewBox='0 0 11 20'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className='mt-2 cursor-pointer'
						>
							<path
								fillRule='evenodd'
								clipRule='evenodd'
								d='M10.6201 0.98957C10.1301 0.49957 9.34006 0.49957 8.85006 0.98957L0.540059 9.29957C0.150059 9.68957 0.150059 10.3196 0.540059 10.7096L8.85006 19.0196C9.34006 19.5096 10.1301 19.5096 10.6201 19.0196C11.1101 18.5296 11.1101 17.7396 10.6201 17.2496L3.38006 9.99957L10.6301 2.74957C11.1101 2.26957 11.1101 1.46957 10.6201 0.98957Z'
								fill='#373737'
							/>
						</svg>
						<h1 className='text-2xl font-semibold relative mx-auto'>
							Log in
						</h1>
					</div>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
						}}
					/>
					<p className='text-heading font-semibold text-left mt-6'>
						Enter the 6-digit OTP received on +919998887776 via
						WhatsApp
					</p>
					<div className='my-6 loginotp-div mx-3  flex items-center justify-center'>
						<OtpInput
							value={otp}
							onChange={setOtp}
							containerStyle='login-otp'
							numInputs={6}
							inputType='number'
							renderInput={(props) => <input {...props} />}
							inputStyle={{
								fontSize: 20,
								height: 47,
								width: 47,
								borderRadius: 15,
							}}
							shouldAutoFocus
						/>
					</div>
					<Button
						variant='contained'
						fullWidth
						type='submit'
						style={{
							borderRadius: 20,
							padding: "15px 20px",
							color: "white",
							backgroundColor: "var(--dark-purple)",
							textTransform: "Capitalize",
						}}
					>
						Login
					</Button>

					<p className='text-center my-5 text-sm '>
						Didn't Receive the OTP ?
						{countdown > 0 ? (
							<span className='text-sm ml-2'>
								Resend in{" "}
								<span className='font-semibold'>
									{countdown}
								</span>
							</span>
						) : (
							<span
								className='text-mid-purple text-sm ml-2 cursor-pointer'
								onClick={resendHandler}
							>
								Resend
							</span>
						)}
					</p>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
							marginTop: "3rem",
						}}
					/>
				</form>
			</div>
		</>
	);
};

export default LoginPhoneOTP;
