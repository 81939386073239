import React, { useEffect, useState } from "react";
import "./SignupForm.css";

import { Button, Divider, TextField } from "@mui/material";
import { Toaster, toast } from "react-hot-toast";
import { useDispatch, useSelector } from "react-redux";
import {
	signUpUserWithEmail,
	signUpUserWithPhone,
} from "../../../../redux/features/auth/signUpActions";
import { useNavigate } from "react-router-dom";

const SignupForm = ({ setSignupForm, setEmailOTP, setPhoneOTP }) => {
	const [name, setName] = useState("");
	const [emailOrphone, setEmailOrPhone] = useState("");
	const [referral, setReferral] = useState("");

	const navigate = useNavigate();
	const dispatch = useDispatch();
	const emailRegex = /^\S+@\S+\.\S+$/;

	const validateSignUp = (data) => {
		if (!data.name) {
			toast.error("Full Name cannot be empty");
			return false;
		} else if (!data.email && !data.phone) {
			toast.error("Email / Phone No cannot be empty");
			return false;
		} else if (data.email && !emailRegex.test(data.email)) {
			toast.error("Email Entered is invalid !");
			return false;
		} else if (data.phone && data.phone.length < 10) {
			toast.error("Phone No Entered is invalid !");
			return false;
		}
		return true;
	};

	const { userInfo, token } = useSelector((state) => state.signup);

	const handleSubmit = (e) => {
		e.preventDefault();

		let data = { name, referral };
		if (/^\d+$/.test(emailOrphone)) {
			data.phone = emailOrphone;
		} else if (typeof emailOrphone === "string" && emailOrphone !== "") {
			data.email = emailOrphone;
		} else if (emailOrphone === "") {
			data = { name, referral };
		}

		if (!validateSignUp(data)) return;

		if (data.email) {
			dispatch(signUpUserWithEmail(data));
		}
		if (data.phone) {
			dispatch(signUpUserWithPhone(data));
		}
	};

	const handleComponents = async () => {
		if (userInfo && token) {
			if (userInfo.email) {
				toast.success(`OTP Sent to ${userInfo.email}`);
				setTimeout(() => {
					setEmailOTP();
				}, 550);
			} else if (userInfo.phone) {
				toast.success(`OTP Sent to ${userInfo.phone}`);
				setTimeout(() => {
					setPhoneOTP();
				}, 550);
			}
		}
	};

	useEffect(() => {
		handleComponents();
		// eslint-disable-next-line
	}, [userInfo]);

	return (
		<>
			<Toaster />

			<div className='signup-wrapper relative'>
				<form className='signup-form relative' onSubmit={handleSubmit}>
					<div className='flex my-4 align-center justify-center relative'>
						<h1 className='text-2xl font-semibold relative mx-auto'>
							Sign Up
						</h1>
						<svg
							width='30'
							height='31'
							viewBox='0 0 30 31'
							fill='none'
							xmlns='http://www.w3.org/2000/svg'
							className='mt-1 cursor-pointer'
							onClick={() => navigate("/")}
						>
							<ellipse
								cx='15'
								cy='15.1351'
								rx='15'
								ry='15.0472'
								fill='#C8C8C8'
							/>
							<path
								d='M10.5923 9.42876L14.7783 13.628L18.9644 9.44149C19.1311 9.27136 19.361 9.1791 19.5986 9.18699C20.0757 9.21791 20.4558 9.59915 20.4866 10.0777C20.4889 10.3075 20.3971 10.5283 20.2329 10.6885L16.0341 14.9132L20.2329 19.1378C20.3971 19.2981 20.4889 19.5188 20.4866 19.7486C20.4558 20.2272 20.0757 20.6085 19.5986 20.6394C19.361 20.6473 19.1311 20.555 18.9644 20.3849L14.7783 16.1984L10.605 20.3849C10.4383 20.555 10.2083 20.6473 9.97072 20.6394C9.48425 20.6139 9.09546 20.2239 9.07009 19.7359C9.06781 19.5061 9.15952 19.2853 9.32379 19.1251L13.5225 14.9132L9.3111 10.6885C9.1514 10.5262 9.06438 10.3058 9.07009 10.0777C9.10091 9.59915 9.48096 9.21791 9.95804 9.18699C10.1937 9.17579 10.4234 9.26334 10.5923 9.42876Z'
								fill='#888888'
							/>
						</svg>
					</div>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
						}}
					/>
					<div className='mt-6 mb-3'>
						<TextField
							id='outlined-required'
							label='Full Name'
							className='signup-input'
							fullWidth
							value={name}
							onChange={(e) => setName(e.target.value)}
							autoFocus={true}
							InputProps={{
								style: { borderRadius: 20 },
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused fieldset": {
										borderColor: "var(--mid-purple)",
									},
								},
								"& label.Mui-focused": {
									color: "var(--mid-purple)",
								},
							}}
						/>
					</div>
					<div className='mb-3'>
						<TextField
							id='outlined-required'
							label='Email / Phone No.'
							className='signup-input'
							value={emailOrphone}
							onChange={(e) => setEmailOrPhone(e.target.value)}
							fullWidth
							InputProps={{
								style: { borderRadius: 20 },
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused fieldset": {
										borderColor: "var(--mid-purple)",
									},
								},
								"& label.Mui-focused": {
									color: "var(--mid-purple)",
								},
							}}
						/>
					</div>
					<div className='mb-3'>
						<TextField
							id='outlined-required'
							label='Referral (if any)'
							className='signup-input'
							value={referral}
							onChange={(e) => setReferral(e.target.value)}
							fullWidth
							InputProps={{
								style: { borderRadius: 20 },
							}}
							sx={{
								"& .MuiOutlinedInput-root": {
									"&.Mui-focused fieldset": {
										borderColor: "var(--mid-purple)",
									},
								},
								"& label.Mui-focused": {
									color: "var(--mid-purple)",
								},
							}}
						/>
					</div>

					<Button
						variant='contained'
						fullWidth
						type='submit'
						style={{
							borderRadius: 20,
							padding: "15px 20px",
							color: "white",
							backgroundColor: "var(--dark-purple)",
							textTransform: "Capitalize",
						}}
					>
						Sign Up
					</Button>

					<p className='text-description text-sm font-semibold text-center py-4'>
						Or Signin with Google
					</p>

					<div className='mb-5'>
						<Button
							variant='contained'
							fullWidth
							style={{
								borderRadius: 20,
								padding: "15px 20px",
								color: "black",
								backgroundColor: "#f5f5f5",
								textTransform: "Capitalize",
							}}
						>
							<svg
								width='20'
								height='21'
								viewBox='0 0 20 21'
								fill='none'
								xmlns='http://www.w3.org/2000/svg'
								className='mx-2'
							>
								<path
									d='M18.1686 9.3077H17.4992V9.27311H10.0201V12.6064H14.7166C14.0314 14.5469 12.1903 15.9398 10.0201 15.9398C7.26653 15.9398 5.03402 13.701 5.03402 10.9398C5.03402 8.17853 7.26653 5.93978 10.0201 5.93978C11.2911 5.93978 12.4475 6.42061 13.3279 7.20603L15.6785 4.84895C14.1943 3.46186 12.209 2.60645 10.0201 2.60645C5.43083 2.60645 1.70996 6.3377 1.70996 10.9398C1.70996 15.5419 5.43083 19.2731 10.0201 19.2731C14.6094 19.2731 18.3302 15.5419 18.3302 10.9398C18.3302 10.381 18.2729 9.83561 18.1686 9.3077Z'
									fill='#FFC107'
								/>
								<path
									d='M2.66797 7.06103L5.39826 9.06895C6.13704 7.23478 7.92621 5.93978 10.0199 5.93978C11.291 5.93978 12.4473 6.42061 13.3278 7.20603L15.6783 4.84895C14.1941 3.46186 12.2088 2.60645 10.0199 2.60645C6.82802 2.60645 4.05992 4.41353 2.66797 7.06103Z'
									fill='#FF3D00'
								/>
								<path
									d='M10.0202 19.2731C12.1667 19.2731 14.1171 18.4493 15.5917 17.1097L13.0197 14.9272C12.1854 15.561 11.1483 15.9397 10.0202 15.9397C7.8587 15.9397 6.02341 14.5577 5.33201 12.6289L2.62207 14.7227C3.9974 17.4214 6.79044 19.2731 10.0202 19.2731Z'
									fill='#4CAF50'
								/>
								<path
									d='M18.1685 9.30802H17.4991V9.27344H10.02V12.6068H14.7165C14.3874 13.5389 13.7895 14.3426 13.0183 14.928L13.0196 14.9272L15.5916 17.1097C15.4096 17.2755 18.3302 15.1068 18.3302 10.9401C18.3302 10.3814 18.2728 9.83594 18.1685 9.30802Z'
									fill='#1976D2'
								/>
							</svg>
							Google
						</Button>
					</div>
					<p className='text-description text-xs font-semibold text-center my-4'>
						By signing up, I agree to the{" "}
						<a href='/termsofuse' className='text-mid-purple'>
							Terms of use
						</a>
						{"\u00A0"}and{"\u00A0"}
						<a href='/privacypolicy' className='text-mid-purple'>
							Privacy Policy
						</a>
					</p>
					<Divider
						style={{
							backgroundColor: "#eeeeee",
							height: "4px",
							border: "none",
							marginLeft: "-2rem",
							marginRight: "-2rem",
						}}
					/>
					<p className='text-center my-4'>
						<a className='text-mid-purple' href='/login'>
							Already have an account? Log In
						</a>
					</p>
				</form>
			</div>
		</>
	);
};

export default SignupForm;
