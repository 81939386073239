import { createAsyncThunk } from "@reduxjs/toolkit";
import { AxiosInstance } from "../../../utils/AxiosInstance";
import { setUserDataLocal } from "../../../utils/setLocalStorage";

export const getProfileData = createAsyncThunk(
   "getProfileData",
   async (data, { rejectWithValue }) => {
      const URL = "/auth/authstatus"
      console.log(data);
      try {
         let res = await AxiosInstance.post(URL, data)
         console.log(res);
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)

export const updateProfile = createAsyncThunk(
   "updateProfile",
   async (data, { rejectWithValue }) => {
      const URL = "/profile/update"
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const newData = new URLSearchParams()
      newData.append("id", _id)
      newData.append("name", data?.name)
      newData.append("avatar", data?.avatar)
      console.log(newData);

      try {
         let res = await AxiosInstance.put(URL, newData.toString(), {
            headers: {
               'Content-Type': 'application/x-www-form-urlencoded',
               Authorization: token
            }
         })
         console.log(res);
         setUserDataLocal(res.data)
         return { response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)


export const sendProfileEmailOTP = createAsyncThunk(
   "sendProfileEmailOTP",
   async (data, { rejectWithValue }) => {
      const URL = "/profile/sendotp"
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const newData = { ...data, id: _id }
      console.log(newData);
      try {
         let res = await AxiosInstance.post(URL, newData, {
            headers: {
               Authorization: token

            }
         })
         console.log(res);
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)

export const verifyAddEmailOTP = createAsyncThunk(
   "verifyAddEmailOTP",
   async (data, { rejectWithValue }) => {
      const URL = "/profile/addcredential"
      const { sessionId, addCredential: { email }, otp } = data
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const newData = { id: _id, email, otp }
      console.log(newData, sessionId);
      try {
         let res = await AxiosInstance.put(URL, newData, {
            headers: {
               Authorization: token,
               token: sessionId
            }
         })
         console.log(res);
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)


export const sendProfilePhoneOTP = createAsyncThunk(
   "sendProfilePhoneOTP",
   async (data, { rejectWithValue }) => {
      const URL = "/profile/sendotp"
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const newData = { ...data, id: _id }
      console.log(newData);
      try {
         let res = await AxiosInstance.post(URL, newData, {
            headers: {
               Authorization: token
            }
         })
         console.log(res);
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)

export const verifyAddPhoneOTP = createAsyncThunk(
   "verifyAddPhoneOTP",
   async (data, { rejectWithValue }) => {
      console.log(data);
      const URL = "/profile/addcredential"
      const { sessionId, addCredential: { phone }, otp } = data
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const newData = { id: _id, phone, otp }
      console.log(newData);
      try {
         let res = await AxiosInstance.put(URL, newData, {
            headers: {
               token: sessionId,
               Authorization: token
            }
         })
         console.log(res);
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log(error);
         return rejectWithValue({
            status: error?.response?.status,
            code: error?.code,
            data: error?.response?.data
         })
      }
   }
)


export const updatePassword = createAsyncThunk(
   "updatePassword",
   async (data, { rejectWithValue }) => {
      const URL = "/profile/updatepassword"
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const newData = { id: _id, ...data }
      console.log("Act data", newData);
      try {
         let res = await AxiosInstance.put(URL, newData, {
            headers: {
               Authorization: token,
            }
         })
         console.log("Act", res);
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log("Act", error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)

export const createNewPassword = createAsyncThunk(
   "createNewPassword",
   async (data, { rejectWithValue }) => {
      const URL = "/profile/updatepassword"
      const token = JSON.parse(localStorage.getItem("token"))
      const { _id } = JSON.parse(localStorage.getItem("user"))
      const { sessionId, newPass, otp } = data
      const newData = { id: _id, newPass, otp }
      console.log(newData);
      try {
         let res = await AxiosInstance.put(URL, newData, {
            headers: {
               Authorization: token,
               token: sessionId
            }
         })
         console.log("Act", res);
         return { data, response: JSON.parse(JSON.stringify(res)) }
      } catch (error) {
         console.log("Act", error);
         return rejectWithValue({
            status: error.response.status,
            code: error.code,
            data: error.response.data
         })
      }
   }
)